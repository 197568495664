import React, {useState, useEffect} from "react";
import { Audios } from "../Utility/Audios";
import Sparks from "../Media/Images/Sparks.gif";

export const SparksComponent: React.FC = () => {

    const [windowSize, setWindowSize] = useState({ width: window.innerWidth, height: window.innerHeight });

    useEffect(() => {
        const handleResize = () => {
            setWindowSize({ width: window.innerWidth, height: window.innerHeight });
        };

        window.addEventListener('resize', handleResize);

        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (<>
        <audio autoPlay src={Audios.SPARKS} />
        <img
            width={windowSize.width < 1000 ? "200%" : "80%"}
            style={{ position: "absolute" }}
            src={Sparks}></img>
    </>
    )
}